import React from 'react';
import ReportDesigner from '../../components/report-designer/report-designer';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import './studio.scss';

export default function Studio() {
  return (
    <React.Fragment>
      <div className={'content-block'}>
          <TabPanel swipeEnabled={false}>
            {/* icon="textdocument" */}
            <Item title="Report">
              <ReportDesigner title={'Components'}/>
            </Item>
            {/* icon="detailslayout" */}
            <Item title="Base knowledge">
              This should be the base knowledge
            </Item>
          </TabPanel>
      </div>
    </React.Fragment>
  )
}