import React, {useState, useCallback} from 'react';
import { useDrag } from 'react-dnd';
import ArrangementContainer from './arrangement-container';
import {ARRANGEMENT} from '../constants';

export default function ChangePattern(props) {
  
  const [initial, setInitial] = useState({name: 'initial', label: 'Initial'});
  const [change, setChange] = useState({name: 'change', label: 'Change'});
  const [final, setFinal] = useState({name: 'final', label: 'Final'});

  let currentNestedPattern;

  const setNestedPattern = (state, props) => ({
    ...state,
    pattern: currentNestedPattern
  });

  const handleDrop = useCallback(
    (container, arrangement) => {
      //console.log('container', container)
      //console.log('arrangement', arrangement)
      currentNestedPattern = arrangement.key;
      switch(container.name) {
        case 'initial':
          setInitial(setNestedPattern)
          break;
        case 'change':
          setChange(setNestedPattern)
          break;
        case 'arrangement':
          setFinal(setNestedPattern)
          break;
        default:
          break;
      }
    }
  )

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: ARRANGEMENT,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }))

  return (
    <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1}}>
      <div ref={drag} className="arrangement">
        <div>Change</div>
        <ArrangementContainer model={initial} onDrop={handleDrop} />
        <ArrangementContainer model={change} onDrop={handleDrop} />
        <ArrangementContainer model={final} onDrop={handleDrop} />
      </div>
    </div>
  )
}