import React from 'react';
//import ReportGrid from '../../components/report-grid/report-grid';
import './entities.scss';

export default function Entities() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Entities</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          This should be the entity grid
          {/*
          <ReportGrid/>
          */}
        </div>
      </div>
    </React.Fragment>
)}
