import React, {useState, useCallback} from 'react';
import { useDrag } from 'react-dnd';
import ArrangementContainer from './arrangement-container';
import {ARRANGEMENT} from '../constants';

export default function IdentityPattern(props) {

  const [right, setRight] = useState({name: 'right', label: 'Right'});
  const [left, setLeft] = useState({name: 'left', label: 'Left'});

  let currentNestedPattern;

  const setNestedPattern = (state, props) => ({
    ...state,
    pattern: currentNestedPattern
  });

  const handleDrop = useCallback(
    (container, arrangement) => {
      console.log('Identity')
      console.log('container', container)
      console.log('arrangement', arrangement)
      currentNestedPattern = arrangement.key;
      switch(container.name) {
        case 'right':
          setRight(setNestedPattern)
          break;
        case 'left':
          setLeft(setNestedPattern)
          break;
        default:
          break;
      }
    }
  )

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: ARRANGEMENT,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }))

  return (
    <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1}}>
      <div ref={drag} className="arrangement">
        <div>Identity</div>
        <ArrangementContainer model={right} onDrop={handleDrop} />
        <ArrangementContainer model={left} onDrop={handleDrop} />
      </div>
    </div>
  )
}