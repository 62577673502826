import React, {useState, useCallback} from 'react';
import { useDrag } from 'react-dnd';
import ArrangementContainer from './arrangement-container'
import {ARRANGEMENT} from '../constants';

export default function ProsePattern(props) {
  //{name: 'children', label: 'Children'}
  const [children, setChildren] = useState([]);
  const [newChild, setNewPart] = useState({name: 'children', label: 'Insert a new child'});

  let currentNestedPattern;

  const setNestedPattern = (state, props) => ({
    ...state,
    pattern: currentNestedPattern
  });

  const createChild = (state, props) => {
    console.log(state);
    return [
    ...state,
    {
      id: state.length + 1,
      name: 'child',
      label: 'Child',
      pattern: currentNestedPattern
    }
  ]}

  const handleDrop = useCallback(
    (container, arrangement) => {
      //console.log('Prose')
      //console.log('container', container)
      //console.log('arrangement', arrangement)
      currentNestedPattern = arrangement.key;
      if(container.name === 'children') {
        setChildren(createChild);
      }
    }
  )

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: ARRANGEMENT,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }))

  return (
    <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1}}>
      <div ref={drag} className="arrangement">
        <div>Prose</div>
        {
          children.map(child => (
            <ArrangementContainer key={child.id} model={child} />)
          )
        }
        <ArrangementContainer model={newChild} onDrop={handleDrop} />
      </div>
    </div>
  )
}