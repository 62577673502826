import React from 'react';
//import { useState, useEffect } from 'react'
import { useDrop } from 'react-dnd';
import {ARRANGEMENT
        , SINGLE_PATTERN
        , PROSE_PATTERN
        , WHOLE_PARTS_PATTERN
        , AGGREGATION_PATTERN
        , CHANGE_PATTERN
        , IDENTITY_PATTERN
        , FUNCTION_PATTERN} from '../constants';

import SinglePattern from './single-pattern'
import ProsePattern from './prose-pattern';
import WholePartsPattern from './whole-parts-pattern';
import AggregationPattern from './aggregation-pattern';
import ChangePattern from './change-pattern';
import IdentityPattern from './identity-pattern';
import FunctionPattern from './function-pattern';
import '../common.scss';

export default function ArrangementContainer(props) {
  
  const [{ canDrop, isOver, isOverCurrent }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: ARRANGEMENT,
    drop: (item, monitor) => {
      //console.log('props', props);
      const didDrop = monitor.didDrop();
      if(didDrop)
        return;
      props.onDrop(props.model, item);
    },
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({shallow: true}),
      canDrop: monitor.canDrop()
    })
  }))
  //console.log('Initial', props);
  let arrangement;
  switch (props.model.pattern) {
    case SINGLE_PATTERN:
      arrangement = <SinglePattern />
      break;
    case PROSE_PATTERN:
      arrangement = <ProsePattern />
      break;
    case WHOLE_PARTS_PATTERN:
      arrangement = <WholePartsPattern />
      break;
    case AGGREGATION_PATTERN:
      arrangement = <AggregationPattern />
      break;
    case CHANGE_PATTERN:
      arrangement = <ChangePattern />
      break;
    case IDENTITY_PATTERN:
      arrangement = <IdentityPattern />
      break;
    case FUNCTION_PATTERN:
      arrangement = <FunctionPattern />
      break;
    default:
      arrangement = <div>Drop your arrangement here!</div>
      break;
  }
  return (
      <div
        ref={drop}
        style={{ backgroundColor: isOverCurrent && isOver ? '#262630' : '#363640' }}
        className="arrangement-container"
      >
        <div>{props.model.label}</div>
        {arrangement}
      </div>
    )
}