import React from 'react';
//import ReportGrid from '../../components/report-grid/report-grid';
import './units.scss';

export default function Units() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Units</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          This should be the unit grid
          {/*
          <ReportGrid/>
          */}
        </div>
      </div>
    </React.Fragment>
)}
