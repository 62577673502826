import React, { useState } from 'react';
import './account.scss';
import Form from 'devextreme-react/form';

export default function Account() {
  const [notes, setNotes] = useState(
    'Charles Hoffman is credited as being the "father of XBRL."'
  );
  const employee = {
    ID: 1,
    FirstName: 'Charles',
    LastName: 'Hoffman',
    Prefix: 'Mr.',
    Position: 'Chairman',
    Picture: 'https://cdn.icon-icons.com/icons2/2506/PNG/512/user_icon_150670.png',
    //BirthDate: new Date('1974/11/5'),
    //HireDate: new Date('2005/05/11'),
    Notes: notes,
    Address: 'Seattle'
  };

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Account</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <div className={'form-avatar'}>
          <img
            alt={''}
            src={`${
              employee.Picture
            }`}
          />
        </div>
        <span>{notes}</span>
      </div>

      <div className={'content-block dx-card responsive-paddings'}>
        <Form
          id={'form'}
          defaultFormData={employee}
          onFieldDataChanged={e => e.dataField === 'Notes' && setNotes(e.value)}
          labelLocation={'top'}
          colCountByScreen={colCountByScreen}
        />
      </div>
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4
};
