import React, { useState, useCallback, useRef } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LayoutContainer from './layout-container';
import { DndProvider } from 'react-dnd';
import TabPanel, { Item } from "devextreme-react/tab-panel";
//import Button from 'devextreme-react/button';
//import Drawer from 'devextreme-react/drawer';

export default function ReportEditor({ title, children }) {  
  return (
    <TabPanel swipeEnabled={false}>
      <Item title="Rendering">
        This should be the renderings
      </Item>
      <Item title="Model structure">
        <DndProvider backend={HTML5Backend}>
          <LayoutContainer/>
        </DndProvider>
      </Item>
      <Item title="Fact table">
        This should be the fact table
      </Item>
      <Item title="Concepts">
        This should be the concepts table
      </Item>
      <Item title="Business rules structure">
        This should be the business rules structure
      </Item>
      <Item title="Business rules validation results">
        This should be the business rules validation results
      </Item>
    </TabPanel>
  );
}