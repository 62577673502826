import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import './component-navigator.scss';

import * as events from 'devextreme/events';

export default function ComponentNavigator(props) {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;

  const { isLarge } = useScreenSize();
  function normalizePath () {
    return navigation.map((item) => {
      if(item.path && !(/^\//.test(item.path))){
        item.path = `/${item.path}`;
      }
      return {...item, expanded: isLarge};
    });
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { navigationData: { currentPath } } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', e => {
      openMenu(e);
    });
  }, [openMenu]);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <TabPanel>
        <Item title="Index">
          <div className={'menu-container'}>
          This should be the component navigator
            {/*
            <TreeView
              ref={treeViewRef}
              items={items}
              keyExpr={'path'}
              selectionMode={'single'}
              focusStateEnabled={false}
              expandEvent={'click'}
              onItemClick={selectedItemChanged}
              onContentReady={onMenuReady}
              width={'100%'}
            />
            */}
          </div>
        </Item>
        <Item title="Disclosures">
          <div className={'menu-container'}>
          This should be the block navigator
            {/*
            <TreeView
              ref={treeViewRef}
              items={items}
              keyExpr={'path'}
              selectionMode={'single'}
              focusStateEnabled={false}
              expandEvent={'click'}
              onItemClick={selectedItemChanged}
              onContentReady={onMenuReady}
              width={'100%'}
            />
            */}
          </div>
        </Item>
        <Item title="Networks">
          <div className={'menu-container'}>
            This should be the newtwork navigator
            {/*
            <TreeView
              ref={treeViewRef}
              items={items}
              keyExpr={'path'}
              selectionMode={'single'}
              focusStateEnabled={false}
              expandEvent={'click'}
              onItemClick={selectedItemChanged}
              onContentReady={onMenuReady}
              width={'100%'}
            />
            */}
          </div>
        </Item>
      </TabPanel>
    </div>
  );
}
