//import React, {useState, useCallback} from 'react';
import { useDrag } from 'react-dnd';
//import ArrangementContainer from './arrangement-container';
import {ARRANGEMENT} from '../constants';

export default function SinglePattern(props) {
  /*
  const [self, setSelf] = useState({name: 'self', label: 'Concept'});

  const handleDrop = useCallback(
    (container, arrangement) => {
      //console.log('container', container)
      //console.log('arrangement', arrangement)
      if(container.name === 'self') {
          setSelf({pattern: arrangement.key})
      }
    }
  )
  */
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: ARRANGEMENT,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }))

  return (
    <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1}}>
      <div ref={drag} className="arrangement">
        <div>Concept</div>
      </div>
    </div>
  )
}