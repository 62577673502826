export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Studio',
    icon: 'product',
    items: [
      {
        text: 'Report',
        path: '/studio'
      },
      {
        text: 'Units',
        path: '/units'
      },
      {
        text: 'Entities',
        path: '/entities'
      }
    ]
  },
  {
    text: 'Account',
    path: '/account',
    icon: 'user'
  },
  {
    text: 'Settings',
    path: '/settings',
    icon: 'preferences'
  }
];
