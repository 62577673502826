import React, {useState, useCallback} from 'react';
import { useDrag } from 'react-dnd';
import ArrangementContainer from './arrangement-container';
import {ARRANGEMENT} from '../constants';

export default function FunctionPattern(props) {
  
  const [output, setOutput] = useState({name: 'output', label: 'Output'});
  const [inputs, setInputs] = useState([]);
  const [newInput, setNewInput] = useState({name: 'inputs', label: 'Insert a new input'});

  let currentNestedPattern;

  const setNestedPattern = (state, props) => ({
    ...state,
    pattern: currentNestedPattern
  });

  const createInput = (state, props) => [
    ...state,
    {
      id: state.length + 1,
      name: 'input',
      label: 'Input',
      pattern: currentNestedPattern
    }
  ]

  const handleDrop = useCallback(
    (container, arrangement) => {
      //console.log('container', container)
      //console.log('arrangement', arrangement)
      currentNestedPattern = arrangement.key;
      switch(container.name) {
        case 'output':
          setOutput(setNestedPattern)
          break;
        case 'inputs':
          setInputs(createInput)
          break;
        default:
          break;
      }
    }
  )

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: ARRANGEMENT,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }))

  return (
    <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1}}>
      <div ref={drag} className="arrangement">
        <div>Function</div>
        <ArrangementContainer model={output} onDrop={handleDrop} />
        {
          inputs.map(input => (
            <ArrangementContainer key={input.id} model={input} />)
          )
        }
        <ArrangementContainer model={newInput} onDrop={handleDrop} />
      </div>
    </div>
  )
}