//import defaultUser from '../utils/default-user';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

export async function signIn(email, password) {
  const username = email;
  try {
    const user = await Auth.signIn(username, password);
    console.log(email, password);
    return {
      isOk: true,
      data: user
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function signOut() {
  try {
    await Auth.signOut();
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Sign out failed"
    };
  }
}

export async function getUser() {
  try {
    // Send request
    const user = await Auth.currentUserInfo();
    return {
      isOk: true,
      data: user
    };
    //defaultUser
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email, password) {
  const username = email;
  try {
    const request = {
      username,
      password,
      attributes: {
        email
      }
    }
    const { user } = await Auth.signUp(request);
    console.log(user);
    return {
      isOk: true
    };
  }
  catch(e) {
    return {
      isOk: false,
      message: e.message
    };
  }
}

export async function changePassword(email, code, password) {
  try {
    // Send request
    const username = email;
    const data = await Auth.forgotPasswordSubmit(username, code, password)
    console.log(data);
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    const username = email;
    const data = await Auth.forgotPassword(username);
    console.log(data);
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
