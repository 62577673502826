import shortid from "shortid";

export const CONTENT = "content";
export const ARRANGEMENT = "arrangement";
export const ASPECT = "aspect";
export const CONCEPT = "concept";
export const SINGLE_PATTERN = "single";
export const PROSE_PATTERN = "prose";
export const WHOLE_PARTS_PATTERN = "whole-parts";
export const AGGREGATION_PATTERN = "aggregation";
export const CHANGE_PATTERN = "change";
export const IDENTITY_PATTERN = "identity";
export const FUNCTION_PATTERN = "function";

export const ARRANGEMENTS = [
  {
    id: shortid.generate(),
    type: ARRANGEMENT,
    key: SINGLE_PATTERN,
    title: "Concept"
  },
  {
    id: shortid.generate(),
    type: ARRANGEMENT,
    key: PROSE_PATTERN,
    title: "Prose"
  },
  {
    id: shortid.generate(),
    type: ARRANGEMENT,
    key: WHOLE_PARTS_PATTERN,
    title: "Whole-Parts"
  },
  {
    id: shortid.generate(),
    type: ARRANGEMENT,
    key: AGGREGATION_PATTERN,
    title: "Aggregation"
  },
  {
    id: shortid.generate(),
    type: ARRANGEMENT,
    key: CHANGE_PATTERN,
    title: "Change"
  },
  {
    id: shortid.generate(),
    type: ARRANGEMENT,
    key: IDENTITY_PATTERN,
    title: "Identity"
  },
  {
    id: shortid.generate(),
    type: ARRANGEMENT,
    key: FUNCTION_PATTERN,
    title: "Function"
  }
];
