import React from 'react';
import ReportGrid from '../../components/report-grid/report-grid';
import './home.scss';

export default function Home() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Reports</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <ReportGrid/>
        </div>
      </div>
    </React.Fragment>
)}
